<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title> Mapping Token </v-card-title>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="mappingTokens"
          :custom-filter="searchByUsername"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-btn
                color="primary"
                dark
                class="mb-1"
                @click="createMappingToken()"
              >
                New Mapping Token
              </v-btn>
              <v-btn
                color="success"
                dark
                class="mb-1 ml-2"
              >
                <download-csv :data="mappingTokens">
                  Download Mapping Token
                </download-csv>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editMappingToken(item.id)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon
              small
              @click="triggerConfirmDialog(item.id)"
              @click.stop="dialog = true"
            >
              {{ icons.mdiDelete }}
            </v-icon>
          </template>
        </v-data-table>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Confirm Delete
            </v-card-title>

            <v-card-text> Are you sure want to delete this Mapping Token? </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="resetDialogState()"
              >
                No
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="deleteMappingTokenConfirm()"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPencil, mdiDelete, mdiMagnify } from '@mdi/js'
import JsonCSV from 'vue-json-csv'
import MappingTokenService from '../../services/MappingTokenService'

export default {
  components: {
    downloadCsv: JsonCSV,
  },
  data() {
    return {
      search: '',
      itemDialogId: -1,
      dialog: false,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiMagnify,
      },
      mappingTokens: [],
      title: '',
      headers: [
        {
          text: 'Username',
          align: 'start',
          value: 'username',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  mounted() {
    this.retrieveMappingTokens()
  },
  methods: {
    retrieveMappingTokens() {
      MappingTokenService.getAll()
        .then(response => {
          const { data } = response.data
          this.mappingTokens = data.map(this.getDisplayMappingToken)
        })
        .catch(e => {
          console.log(e)
        })
    },

    refreshList() {
      this.retrieveMappingTokens()
    },

    triggerConfirmDialog(id) {
      this.itemDialogId = id
      this.dialog = true
    },

    resetDialogState() {
      this.itemDialogId = -1
      this.dialog = false
    },

    deleteMappingTokenConfirm() {
      this.deleteMappingToken(this.itemDialogId)
      this.resetDialogState()
    },

    editMappingToken(id) {
      this.$router.push({ name: 'mapping-token-details', params: { id } })
    },

    createMappingToken() {
      this.$router.push({ name: 'mapping-token-create' })
    },

    deleteMappingToken(id) {
      MappingTokenService.delete(id)
        .then(() => {
          this.refreshList()
        })
        .catch(e => {
          console.log(e)
        })
    },

    getDisplayMappingToken(mappingToken) {
      return { id: mappingToken.id, username: mappingToken.username }
    },

    searchByUsername(value, search) {
      return (
        value != null
        && search != null
        && typeof value === 'string'
        && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    },
  },
}
</script>
